import React from "react"
import { PageLayout } from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import getMonth from "../utils/getMonth"
import Img from "gatsby-image"
import "./blog.scss"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Blog from "../components/Blog"
import TextSectionBackground from "../components/TextSectionBackground"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => (
  <p className="align-center blog-paragraph">{children}</p>
)

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      console.log(node);
      const { url } = node.data.target.fields.file["en-US"]
      return (
        <div style={{ maxWidth: "920px" }}>
          <img src={url} style={{maxWidth:"650px"}} width="90%" />
        </div>
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { url } = node.data.target.fields
      return (
        <iframe
          id="test"
          width="960"
          height="720"
          allow="fullscreen"
          src={url["en-US"]}
        ></iframe>
      )
    },//images.ctfassets.net/k1rfr1eakw0r/44xtSgs9a0NR0pahpQANd7/e0cd9a8da3ae533df2b106beed41af33/Casey_Pratt_Love_Africa_Marketing_5.jpeg
  },
}

export default ({ data }) => {
  const date = new Date(data.contentfulBlog.time)
  console.log(data.contentfulBlog.body.json);
  return (
    <PageLayout>
      <SEO title={data.contentfulBlog.seoTitle} description={data.contentfulBlog.metaDescription} />
      <TextSectionBackground pa="4% 5%">
          <div style={{ maxWidth: "920px", margin: "0 auto" }}>
            <div className="blog-header">
              <h1 className="blog-header-header">{data.contentfulBlog.header}</h1>
              <p className="date">{`${getMonth(
                date.getMonth()
              )} ${date.getDate()}, ${date.getFullYear()}`}</p>
            </div>
            <Img fluid={data.contentfulBlog.image.fluid}></Img>
            {documentToReactComponents(data.contentfulBlog.body.json, options)}
          </div>
          <div style={{ padding: "4rem 5% 0" }}>
            <h2 className="blog-header-header mb-40">Our other stories</h2>
            <Blog allContentfulBlog={data.allContentfulBlog}></Blog>
          </div>
      </TextSectionBackground>      
    </PageLayout>
  )
}

export const query = graphql`
  query BlogByID($slug: String) {
    contentfulBlog(slug: { eq: $slug }) {
      header
      seoTitle
      metaDescription
      time
      image {
        fluid(maxWidth: 920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      body {
        json
      }
    }
    allContentfulBlog(sort: { fields: time, order: DESC }) {
      nodes {
        header
        time
        slug
        shortText
        contentful_id
        image {
          fixed(width: 350, height: 225) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
  }
`
